import { BaseResouceModel } from '../../../shared/models/base-resource-model';
import '../lines-list/lines-list-interface';

export class Lines extends BaseResouceModel implements ILoja {

    // tslint:disable:variable-name
    lojas: ILines;
    constructor(
        loja?: any[]|ILoja
    ) {
        super();
    }

    static fromJson(jsonData: any): Lines {
        return Object.assign(new Lines(), jsonData);
    }

}
