import { Injectable, Injector } from '@angular/core';

import { BaseResourceService } from '../../../shared/services/base-resource-service';
import { SubLines } from './sublines.model';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubLinesService extends BaseResourceService<SubLines> {
  public baseUrl = environment.baseUrl;

  constructor(protected injector: Injector) {
    super('api/sublinhas', injector, SubLines.fromJson);
  }

  public subLineDescriptionKey = "DESCRIPTION"

  list(): Observable<any> {
    const url = this.baseUrl + 'api/vw_linhas';
    return this.http.get<any>(url);
  }

  get(): Observable<any> {
    const url = this.baseUrl + '/api/vw_linhas';
    return this.http.get<any>(url);
  }

  getById(id): Observable<any> {
    const params = {COD_SUBLINHA: id}
    return this.getWithParams(params)
  }

  getByLine(lineId): Observable<any> {
    const params = {COD_LINHA: lineId}
    return this.getWithParams(params)
  }
}
