import { Injectable, Injector } from '@angular/core';

import { BaseResourceService } from '../../../shared/services/base-resource-service';
import { Products } from './products.model';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseResourceService<Products> {
  public baseUrl = environment.baseUrl;

  constructor(protected injector: Injector) {
    super('api/vw_produtos', injector, Products.fromJson);
  }

  public productCodKey = "COD_PROD"
  public productNameKey = "NOME_PROD"
  public productUnityKey = "UNIDADE"

  getById(id: number): Observable<any> {
    const params = {COD_PROD: id}
    return this.getWithParams(params)
  }

  public fetchProducts(page, size, params:any=null): Observable<any>{
    return this.getAllWithPagination(page, size, params)
  }

}
