import { BaseResouceModel } from '../../../shared/models/base-resource-model';
import '../products-list/products-list-interface';
import { take } from 'rxjs/operators';

export class Products extends BaseResouceModel implements IProduct {

    // tslint:disable:variable-name
    constructor(
        product?: any[]|IProduct
    ) {
        super();
    }

    static fromJson(jsonData: any): Products {
        return Object.assign(new Products(), jsonData);
    }

}
