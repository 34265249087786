import { Injectable, Injector } from '@angular/core';

import { BaseResourceService } from '../../../shared/services/base-resource-service';
import { Lines } from './lines.model';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinesService extends BaseResourceService<Lines> {
  public baseUrl = environment.baseUrl;
  public subLinesApiPath = 'api/sublinhas'

  constructor(protected injector: Injector) {
    super('api/vw_linhas', injector, Lines.fromJson);
  }

  list(): Observable<any> {
    const url = this.baseUrl + 'api/vw_linhas';
    return this.http.get<any>(url);
  }

  get(): Observable<any> {
    const url = this.baseUrl + '/api/vw_linhas';
    return this.http.get<any>(url);
  }
}
